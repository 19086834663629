import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import {v4 as uuid} from 'uuid'
import Link from 'src/components/Link'
import Logo from 'src/images/logo-invert.inline.svg'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  cFooter,
  cLogo,
  cText,
  cMenuLink,
  cLinkCol,
  cSocialCol,
  cSocialLinks,
  cSocialLink,
  cSocialIcon,
  cLegalLinks,
  cLegalLink,
  cFooterRow,
} from "./style.module.scss"

const Footer = () => {

    const data = useStaticQuery(graphql`
        query FooterQuery {
            prismicFooter {
                data {
                    legal_links {
                        link {
                            target
                            url
                        }
                        link_title
                    }
                    menu_links {
                        link {
                            url
                            target
                        }
                        link_title
                    }
                    social_heading {
                        text
                    }
                    social_links {
                        icon {
                            alt
                            gatsbyImageData
                        }
                        link {
                            url
                            target
                        }
                    }
                    text {
                        text
                    }
                }
            }
        }
    `).prismicFooter.data

    return (
        <footer className={cFooter}>
            <Container>
                <Row className={cFooterRow}>
                    <Col xs={{span: 12}} xl={{span: 3}}>
                        <Logo className={cLogo} alt=""/>
                        <div className={cText}>
                        {
                            ReactHtmlParser(data.text.text)
                        }
                        </div>     
                    </Col>
                    <Col xs={{span: 12}}  xl={{span: 3, offset: 3}} className={cLinkCol}>
                        {
                            data.menu_links.map((link) => (
                                <Link
                                    linkUrl={link.link.url}
                                    linkTitle={link.link_title}
                                    linkTarget={link.link.target}
                                    className={cMenuLink}
                                    key={uuid()}
                                />
                            ))
                        }   
                    </Col>
                    <Col xs={{span: 12}} xl={{span: 3}} className={cSocialCol}>
                        <span className={cMenuLink}>
                            {
                                data.social_heading.text
                            }
                        </span>
                        <div className={cSocialLinks}>
                            {
                                data.social_links.map((link) => (
                                    <a
                                        href={link.link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={cSocialLink}
                                        key={uuid()}
                                        aria-label={`Social Link: ${link.link.url}`}
                                    >
                                        <GatsbyImage
                                            alt="Social Icon"
                                            image={link.icon.gatsbyImageData}
                                            className={cSocialIcon}
                                        />
                                    </a>
                                ))
                            }
                        </div>
                        <div className={cLegalLinks}>
                            {
                                data.legal_links.map((link) => (
                                    <Link
                                        linkUrl={link.link.url}
                                        linkTitle={link.link_title}
                                        linkTarget={link.link.target}
                                        className={cLegalLink}
                                        key={uuid()}
                                    />
                                ))
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
