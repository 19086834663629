import React from 'react'
import classNames from 'classnames';

import {
  cLogo,
} from './style.module.scss'


const TextBrand = ({
  className,
}) => {
    const classes = classNames({
        [cLogo]: true,
        [className]: !!className,
    })
  return (
    <div className={classes}>
        <span>{"<"}</span>dev<span>{">"}</span><br />
        solution
    </div>
  )
}

export default TextBrand