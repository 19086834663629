import React from 'react'
import classNames from 'classnames';
import { Container, Row, Col } from 'react-bootstrap'

import {
 cSection,
 cSectionDark,
 cSectionLink,
 cSectionLinkWrapper,
 cSectionHeading,
} from "./style.module.scss"

const SubFooter = ({typ}) => {

  const sectionClasses = classNames({
      [cSection]: true,
      [cSectionDark]: typ === "dark",
  })

  return (
    <section className={sectionClasses}>
      <Container fluid>
        <Row>
          <Col xl={{span: 4, offset: 4}}>
            <h2 className={cSectionHeading}>
              Wir sind bereit mit dir loszustarten. Du auch?
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
          <div className={cSectionLinkWrapper}>
            <a href={`mailto:hi@devsolution.at`} className={cSectionLink}>    
              hi@devsolution.at
            </a>
          </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default SubFooter
