// extracted by mini-css-extract-plugin
export var cFooter = "style-module--c-footer--ba7d0";
export var cFooterRow = "style-module--c-footer-row--7e94b";
export var cLegalLink = "style-module--c-legal-link--da54c";
export var cLegalLinks = "style-module--c-legal-links--2de43";
export var cLinkCol = "style-module--c-link-col--979be";
export var cLogo = "style-module--c-logo--3d6f4";
export var cMenuLink = "style-module--c-menu-link--7fb42";
export var cSocialCol = "style-module--c-social-col--8ce2a";
export var cSocialIcon = "style-module--c-social-icon--ed8bd";
export var cSocialLink = "style-module--c-social-link--520c4";
export var cSocialLinks = "style-module--c-social-links--1b886";
export var cText = "style-module--c-text--0de0b";