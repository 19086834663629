import React, {useState} from 'react'
import {Link} from 'gatsby'
import 'normalize.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/styles/font.scss'
import 'src/styles/global.scss'
import 'src/styles/type.scss'
import 'src/styles/bootstrap.scss'
import 'src/styles/cookie.scss'
import TextBrand from 'src/components/TextBrand'
import Bow from 'src/images/bow.inline.svg'
import SubFooter from 'src/components/SubFooter';
import Footer from 'src/components/Footer';
import Navigation from 'src/components/Navigation';

import {
  dsBow,
  dsLogo,
} from './style.module.scss'


const Layout = ({
  children,
}) => {
  
  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
      <main>
        <Navigation isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
        <Link to={"/"} onClick={() => setIsNavOpen(false)}>
          <TextBrand className={dsLogo} />
        </Link>
        {children}
        <Bow className={dsBow} alt=""/>
        <SubFooter 
          typ="light"
        />
        <Footer />
      </main>
  )
}

export default Layout
