import React from 'react'
import WrapPageElement from './wrap'

export const wrapPageElement = ({ element, props }) => {
  return <WrapPageElement element={element} props={props} />
}


// Logs when the client route changes
export const shouldUpdateScroll = () => {
  return false
}

export const onRouteUpdate = () => {
  window.scrollTo(0, 0)
}