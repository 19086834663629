import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Level1NavLink from 'src/components/Level1NavLink'
import Level2NavLink from 'src/components/Level2NavLink'
import Arrow from 'src/images/arrow.inline.svg'
import {v4 as uuid} from 'uuid'

import {
  cNavigation,
  cNavigationButton,
  cNavigationBar,
  cNavigationBarOpen,
  cNavigationRow,
  cNavigationContainer,
  cLink1Col,
  cLink2Col,
  cArrow,
  cNavLinkCol2Mobile
} from './style.module.scss'


const Navigation = ({isNavOpen, setIsNavOpen}) => {

    const navigationButtonClasses = classNames({
      [cNavigationButton]: true,
    })

    const navigationBarClasses = classNames({
      [cNavigationBar]: true,
      [cNavigationBarOpen]: !!isNavOpen,
    })

    const data = useStaticQuery(graphql`
        query NavQuery {
            prismicNavigation {
                data {
                    links {
                        link {
                            url
                            target
                        }
                        link_title
                    }
                }
            }
            prismicSubNavigation {
                data {
                    links {
                        link {
                            url
                            target
                        }
                        link_title
                    }
                }
            }
        }
    `)

    const navLinks = data.prismicNavigation.data.links 
    const subNavLinks = data.prismicSubNavigation.data.links

    const breakpoints = useBreakpoint()

    useEffect(() => {
        if (isNavOpen) {
            document.body.style.height = '100vh';
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'visible';
        }

        // Clean up the styles when the component unmounts or isNavOpen changes
        return () => {
            document.body.style.height = 'auto';
            document.body.style.overflow = 'visible';
        };
    }, [isNavOpen]);

    return (
        <>
            <button 
                onClick={() => setIsNavOpen(!isNavOpen)} 
                className={navigationButtonClasses}
                aria-label="Open Navigation"
            >
                <div className={navigationBarClasses} />
                <div className={navigationBarClasses} />
            </button>
            {
                isNavOpen && (
                    <nav className={cNavigation}>
                        <Container className={cNavigationContainer}>
                            <Row className={cNavigationRow}>
                                <Col 
                                    className={cLink1Col} 
                                    xs={{span: 12, offset: 0, order: 0}} 
                                    lg={{span: 7, offset: 0, order: 0}}
                                >
                                    {
                                        navLinks.map((link, i) => {
                                            if(i === 0) {
                                                return (
                                                    <>
                                                        <Level1NavLink 
                                                            link={{
                                                                title: link.link_title,
                                                                slug: link.link.url,
                                                            }} 
                                                            onClick={() => setIsNavOpen(!isNavOpen)} 
                                                            key={uuid()}
                                                        />
                                                        {
                                                            !breakpoints.lg && (
                                                                <div className={cNavLinkCol2Mobile}>
                                                                {
                                                                    subNavLinks.map((subnavLink) => {
                                                                        return <Level2NavLink 
                                                                                    link={{
                                                                                        title: subnavLink.link_title,
                                                                                        slug: subnavLink.link.url,
                                                                                    }}
                                                                                    onClick={() => setIsNavOpen(!isNavOpen)} 
                                                                                    key={uuid()}
                                                                                />
                                                                    })
                                                                }
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                )
                                            } else {
                                                return <Level1NavLink 
                                                            link={{
                                                                title: link.link_title,
                                                                slug: link.link.url,
                                                            }} 
                                                            onClick={() => setIsNavOpen(!isNavOpen)}
                                                            key={uuid()}
                                                        />
                                            }
                                        })
                                    }
                                </Col>
                                {
                                    breakpoints.lg && (
                                        <Col 
                                            className={cLink2Col} 
                                            lg={{span: 5, offset: 0, order: 0}}
                                        >
                                            <Arrow className={cArrow} />
                                            {
                                                subNavLinks.map((subnavLink) => {
                                                    return <Level2NavLink 
                                                                link={{
                                                                    title: subnavLink.link_title,
                                                                    slug: subnavLink.link.url,
                                                                }}
                                                                onClick={() => setIsNavOpen(!isNavOpen)}
                                                                key={uuid()}
                                                            />
                                                })
                                            }
                                        </Col>
                                    )
                                }
                            </Row>
                        </Container>
                    </nav>
                )
            }
        </>
    )
}

export default Navigation
