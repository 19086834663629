import React from 'react'
import classNames from 'classnames'
import Link from '../Link'

import {
    cLink,
} from './style.module.scss'

const Level2NavLink = ({
  link,
  className,
  onClick
}) => {

    const linkClasses = classNames({
      [className]: !!className,
      [cLink]: true,
    })

    return (
        <Link 
          linkUrl={`${link.slug === "home" ? "/" : link.slug}`} 
          linkTitle={`- ${link.title}`}
          linkTarget={link.target}
          className={linkClasses}
          onClick={onClick}
        />
    )
}
export default Level2NavLink