import React from 'react'
import { Link as GatsbyLink } from 'gatsby';

function extractValidURL(url) {
  const regex = /^(https?:\/\/[^.]+\.[^.]+)(?!\.\w{2,6})/;
  const match = regex.exec(url);

  if (match) {
    return match[1];
  } else {
    return url ? url.replace(/^(https?|http):\/\//, '') : "";
  }
}

const Link = ({
  linkUrl,
  linkTitle,
  linkTarget,
  className,
  onClick
}) => {

    let validatedLinkUrl = linkUrl === "/home" ? "/" : extractValidURL(linkUrl)

    const onClickFunction = () => {
      !!onClick && onClick()
    }

    return linkTarget === "_blank" ? (
        <a 
            target="_blank" 
            rel="noopener noreferrer" 
            href={linkUrl} 
            className={className}
            onClick={onClick}
        >
            {linkTitle}
        </a>
    ) : (
        <GatsbyLink to={`${validatedLinkUrl}`} className={className} onClick={onClickFunction}>
            {linkTitle}
        </GatsbyLink>
    )
}


export default Link