import React from 'react'
import Layout from './src/components/Layout'

const WrapLayout = ({ element }) => {

  return (
    <Layout>
      {element}
    </Layout>
  )
}

const WrapPageElement = ({ element, props }) => (
    <WrapLayout element={element} props={props} />
)

export default WrapPageElement
